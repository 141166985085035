<template>
  <div>
    <OrganismsGlobalBanner />
    <SiteHeader :menu="primaryMenu" />
    <OrganismsFixedCTA />
    <slot />

    <SiteFooter :neighborhoods="footerNeighborhoodsMenu" />
  </div>
</template>

<script setup>
import { useQuery } from "@vue/apollo-composable";
import { GET_MENUS } from "~/graphql/GetMenus";

const { result, loading } = useQuery(GET_MENUS);

const primaryMenu = computed(() => {
  const flatMenu = result.value?.primaryMenu?.menuItems?.nodes ?? [];
  if (flatMenu.length === 0) return [];
  return flatMenu.length === 0 ? [] : flatListToHierarchical(flatMenu);
});

const footerNeighborhoodsMenu = computed(() => {
  return result.value?.footerNeighborhoodsMenu?.nodes ?? [];
});
</script>
