<template>
  <div
    v-if="isBannerVisible && banner && banner.showBanner"
    :style="bannerColor"
    :class="[
      'organism global-banner z-[50]',
      'relative',
    ]"
  >
    <button
      @click="isBannerVisible = false"
      class="absolute top-2 right-2 text-white text-2xl"
    >
      <XCircleIcon
        class="w-7 h-7 stroke-white hover:stroke-fr-cta-red"
        aria-hidden="true"
      />
    </button>
    <div
      class="flex flex-wrap items-center justify-center sm:px-2 px-8 py-4 mx-auto text-center md:justify-start md:text-left max-w-7xl gap-4"
    >
      <h2
        class="font-figtree font-semibold md:text-[22px] text-[18px] text-white leading-none"
      >
        {{ banner.text }}
      </h2>
      <NuxtLink
        :to="banner.btnLink"
        :title="banner.btnText"
        :style="textColor"
        class="rounded shadow bg-white font-figtree font-medium text-[13px] px-4 py-1 text-right"
      >
        {{ banner.btnText }}
      </NuxtLink>
    </div>
  </div>
</template>

<style></style>

<script setup>
import { XCircleIcon } from "@heroicons/vue/outline/index.js";
import { GET_BANNER } from "~/graphql/GetBanner.js";
const { result, loading, error } = useQuery(GET_BANNER);

let banner = ref(null);
let isBannerVisible = ref(true); // Renamed from showBanner

onMounted(async () => {
  watchEffect(() => {
    if (result.value && result.value.globalBanner) {
      banner.value = result.value.globalBanner;
    }
  });
});

const bannerColor = computed(() => {
  // Extract the color value, ensuring it defaults to '#fde047' if not provided.
  const color =
    banner.value && banner.value.color ? banner.value.color : "#fde047";
  // Return an object with the CSS property you want to dynamically update.
  // In this case, it's the background color.
  return { backgroundColor: color };
});

const textColor = computed(() => {
  // Extract the color value, ensuring it defaults to '#fde047' if not provided.
  const color =
    banner.value && banner.value.color ? banner.value.color : "#fde047";
  // Return an object with the CSS property for text color.
  return { color: color };
});
</script>

