<template>
  <Popover class="relative sticky top-0 z-50 bg-white site-header isolate drop-shadow" :class="{ scrolled: isScrolled }"
    v-slot="{ open, close }">
    <div
      class="flex items-center justify-between px-4 py-6 mx-auto max-w-7xl sm:px-6 xl:px-0 xl:justify-start xl:space-x-10 transition-all duration-300"
      :class="{ scrolled: isScrolled }">
      <div class="flex justify-start lg:w-0 lg:flex-1">
        <NuxtLink href="/">
          <span class="sr-only">Workflow</span>
          <img class="w-[148px] h-[100px] object-contain transition-all duration-300" :class="{
              'w-[148px] h-[100px]': isScrolled,
            }" :style="{
              width: isScrolled && isMobile ? '100px !important' : '',
              height: isScrolled && isMobile ? '80px !important' : '',
            }" src="/img/logo-consort-white.svg" alt="Consort Homes logo" loading="lazy" />
        </NuxtLink>
      </div>
      <div class="-my-2 -mr-2 xl:hidden">
        <PopoverButton
          class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
          <span class="sr-only">Open menu</span>
          <MenuIcon class="w-6 h-6" aria-hidden="true" />
        </PopoverButton>
      </div>
      <PopoverGroup as="nav" class="hidden desktop-nav xl:flex space-x-10">
        <ul class="xl:flex space-x-10">
          <li v-for="item in menu" :id="'menu-item-' + item.databaseId" :key="item.id"
            :class="item.cssClasses.join(' ')">
            <Popover v-if="item.children.length" class="relative" v-slot="{ open, close }">
              <PopoverButton @mouseover="(e) => hoverPopover(e, open)" @mouseleave="closePopover(close)" :class="[
                  open ? 'text-gray-900' : 'text-gray-500',
                  'group bg-white rounded-md inline-flex items-center font-semibold theme-text-base leading-7 uppercase hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500/0',
                ]">
                <span>{{ item.label }}</span>
                <ChevronDownIcon :class="[
                    open ? 'text-gray-600' : 'text-gray-400',
                    'ml-2 h-5 w-5 group-hover:text-gray-500',
                  ]" aria-hidden="true" />
              </PopoverButton>

              <transition enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-200" leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1">
                <div>
                  <PopoverPanel @mouseover.prevent="popoverHover = true" @mouseleave.prevent="closePopover(close)"
                    class="absolute z-10 w-screen max-w-sm mt-3 -ml-4 submenu-container transform lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                    <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div class="relative px-4 pt-4 bg-white inner">
                        <ul v-for="item in item.children" :key="item.id" :class="
                            item.cssClasses.join(' ') +
                            ' theme-text-sm font-medium text-gray-500'
                          ">
                          <li :id="'menu-item-' + item.databaseId" class="p-2 underline uppercase">
                            {{ item.label }}
                          </li>
                          <li>
                            <ul>
                              <li v-for="item in item.children" :key="item.id" :id="'menu-item-' + item.databaseId"
                                :class="
                                  item.cssClasses.join(' ') +
                                  ' theme-text-lg leading-tight py-[2.5px] text-gray-900'
                                ">
                                <PopoverButton as="a" :href="item.uri" class="block p-2 hover:bg-gray-100"
                                  :target="item.target ? item.target : '_self'">
                                  <div>
                                    <span class="block mb-1">
                                      {{ item.label }}
                                      <span v-if="
                                          item.connectedNode?.node?.neighborhood
                                            ?.city.length
                                        ">
                                        in
                                        {{
                                        item.connectedNode?.node?.neighborhood
                                        ?.city
                                        }}
                                      </span>
                                    </span>
                                    <span v-if="item.description"
                                      class="block !text-sm font-normal text-gray-500 theme-text-sm">
                                      {{ item.description }}
                                    </span>
                                    <span v-if="
                                        item.connectedNode?.node?.__typename ===
                                        'Neighborhood'
                                      " class="block !text-sm font-normal text-gray-500 theme-text-sm">
                                      {{
                                      getNeighborhoodNavSubtitle(
                                      item.connectedNode?.node?.types,
                                      getRoundedPrice(
                                      item.connectedNode?.node?.basePrice,
                                      ),
                                      item.connectedNode?.node?.neighborhood
                                      ?.status,
                                      )
                                      }}
                                    </span>
                                  </div>
                                </PopoverButton>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </PopoverPanel>
                </div>
              </transition>
            </Popover>

            <NuxtLink v-else :href="item.uri" class="font-medium text-gray-500 theme-text-lg hover:text-gray-900">
              {{ item.label }}
            </NuxtLink>
          </li>
        </ul>
      </PopoverGroup>
      <div class="items-center justify-end hidden xl:flex xl:flex-1 lg:w-0">
        <NuxtLink v-if="false" href="https://lp.consort-homes.com/warranty2-0"
          class="text-base font-medium text-secondary-500 whitespace-nowrap hover:text-secondary-500/80">
          Warranty Info
        </NuxtLink>
        <NuxtLink v-if="enableFavorites" to="/my-favorites/"
          class="inline-flex items-center justify-center px-4 py-2 ml-8 text-base font-semibold text-white uppercase border border-transparent bg-heart-red whitespace-nowrap rounded-md shadow-sm hover:bg-heart-red/80">
          <HeartIcon class="w-5 h-5 mr-2 group-hover:text-gray-500 fill-white" aria-hidden="true" />
          Favorites
        </NuxtLink>
      </div>
    </div>

    <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
      <div>
        <PopoverPanel focus class="absolute inset-x-0 top-0 p-2 transition transform origin-top-right xl:hidden">
          <div class="bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y-2 divide-gray-50">
            <div class="px-5 pt-5 pb-6">
              <div class="flex items-center justify-between">
                <div>
                  <PopoverButton as="a" href="/">
                    <img class="object-contain w-[100px] h-[80px]" src="/img/logo-consort-white.svg" alt="Workflow"
                      loading="lazy" />
                  </PopoverButton>
                </div>
                <div class="-mr-2">
                  <PopoverButton
                    class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                    <span class="sr-only">Close menu</span>
                    <XIcon class="w-6 h-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="flex flex-col flex-grow mt-5 overflow-auto mobile-screen">
                <nav class="flex-1 px-2 bg-white mobile-nav space-y-1">
                  <template v-for="item in menu" :key="item.id" :id="'mobile-menu-item-' + item.databaseId"
                    :class="item.cssClasses.join(' ')">
                    <Disclosure as="div" v-if="item.children?.length" class="space-y-1" v-slot="{ open }">
                      <div v-if="open">
                        <DisclosureButton as="a" :href="item.uri" :target="item.target ? item.target : '_self'" :class="[
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group w-full flex items-center pr-2 py-2 text-left text-lg font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500',
                          ]">
                          <svg :class="[
                              open
                                ? 'text-gray-400 rotate-90'
                                : 'text-gray-300',
                              'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                            ]" viewBox="0 0 20 20" aria-hidden="true">
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                          {{ item.label }}
                          <span class="ml-2 text-xs italic text-gray-400 underline">(visit {{ item.label }})</span>
                        </DisclosureButton>
                      </div>
                      <div v-else>
                        <DisclosureButton :class="[
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group w-full flex items-center pr-2 py-2 text-left text-lg font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500',
                          ]">
                          <svg :class="[
                              open
                                ? 'text-gray-400 rotate-90'
                                : 'text-gray-300',
                              'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                            ]" viewBox="0 0 20 20" aria-hidden="true">
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                          {{ item.label }}
                        </DisclosureButton>
                      </div>
                      <DisclosurePanel class="space-y-1">
                        <ul v-for="item in item.children" :key="item.id" :class="
                            item.cssClasses.join(' ') +
                            ' theme-text-sm font-medium text-gray-500'
                          ">
                          <li class="pl-10 text-sm underline uppercase">
                            {{ item.label }}
                          </li>
                          <li v-for="item in item.children" :key="item.id" :id="'menu-item-' + item.databaseId" :class="
                              item.cssClasses.join(' ') +
                              ' theme-text-lg text-black'
                            ">
                            <DisclosureButton as="a" :href="item.uri" :target="item.target ? item.target : '_self'"
                              :class="
                                item.cssClasses.join(' ') +
                                ' inline-block w-full py-2 pl-14 pr-2 text-lg font-medium text-gray-600 group rounded-md hover:text-gray-900 hover:bg-gray-50'
                              ">
                              {{ item.label }}
                              <span v-if="
                                  item.connectedNode?.node?.neighborhood?.city
                                    .length
                                ">
                                in
                                {{
                                item.connectedNode?.node?.neighborhood?.city
                                }}
                              </span>
                            </DisclosureButton>
                          </li>
                        </ul>
                      </DisclosurePanel>
                    </Disclosure>

                    <div v-else>
                      <NuxtLink :href="item.uri" :target="item.target ? item.target : '_self'" :class="[
                          item.current
                            ? 'bg-gray-100 text-gray-900'
                            : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group w-full flex items-center pl-7 pr-2 py-2 text-lg font-medium rounded-md',
                        ]">
                        {{ item.label }}
                      </NuxtLink>
                    </div>
                  </template>
                </nav>
              </div>
            </div>
            <div class="px-5 pt-6">
              <div class="mt-6">
                <NuxtLink to="/interactive-home-design/"
                  class="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent bg-heart-red rounded-md shadow-sm hover:bg-heart-red/80">
                  <LightBulbIcon class="w-5 h-5 mr-2 group-hover:text-gray-500" aria-hidden="true" />
                  &#32;Interactive Home Design
                </NuxtLink>
              </div>
            </div>
            <div v-if="enableFavorites" class="px-5 pb-6">
              <div class="mt-6">
                <NuxtLink to="/my-favorites/"
                  class="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent bg-heart-red rounded-md shadow-sm hover:bg-heart-red/80">
                  <HeartIcon class="w-5 h-5 mr-2 group-hover:text-gray-500" aria-hidden="true" />
                  &#32;Favorites
                </NuxtLink>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </div>
    </transition>

    <MoleculesBreadcrumbNav />
  </Popover>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline/index.js";
import { HeartIcon, LightBulbIcon } from "@heroicons/vue/solid/index.js";
import { ChevronDownIcon } from "@heroicons/vue/solid/index.js";

const config = useRuntimeConfig();
const enableFavorites = config.public.enableFavorites === "true" ? true : false;

const props = defineProps({
  menu: Array,
});

const popoverHover = ref(false);
const popoverTimeout = ref();

const hoverPopover = (e: any, open: boolean): void => {
  popoverHover.value = true;
  if (!open) {
    if (e.target && ['BUTTON', 'SPAN', 'SVG'].includes(e.target.nodeName)) {
      e.target.closest('button').click();
    }
  }
};

const closePopover = (close: any): void => {
  popoverHover.value = false;
  if (popoverTimeout.value) clearTimeout(popoverTimeout.value);
  popoverTimeout.value = setTimeout(() => {
    if (!popoverHover.value) {
      close();
    }
  }, 100);
};

const isScrolled = ref(false);
const isMobile = ref(window.innerWidth <= 1024);

const handleScroll = () => {
  isScrolled.value = window.scrollY > 200;
};

const handleResize = () => {
  isMobile.value = window.innerWidth <= 1024;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  window.removeEventListener("resize", handleResize);
});
</script>
