import gql from "graphql-tag";

export const GET_BANNER = gql`
  query GetBanner {
    globalBanner {
      btnLink
      btnText
      color
      showBanner
      text
    }
  }
`;